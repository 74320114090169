<template>
    <div>
        <LiefengContent >
            <template v-slot:title  >平台员工管理</template>
            <template v-slot:toolsbarRight>
                <Form :label-colon="true" :inline="true" class="search">
                    <FormItem >
                        <Input style="width:200px" placeholder="请输入员工姓名" v-model.trim="keyword" ></Input>
                    </FormItem>
                    <Button type="primary" icon="ios-search" @click="getList" style="margin-right: 10px">查询</Button>
                    <Button type="success" @click="resetBtn" icon="ios-refresh" style="margin-right: 10px">重置</Button>
                    <Button icon="ios-add" type="primary" @click="add">新增</Button>
        </Form>
      </template>
      <template v-slot:contentArea>
        <LiefengTable
            :talbeColumns="talbeColumns"
            :tableData="tableData"
            :loading="loading"
            :fixTable="true"
            :curPage="page"
            :total="total"
            :pagesizeOpts="[20, 30, 50, 100]"
            :page-size="pageSize"
            @hadlePageSize="changeTableSize"
            @tableSelect="tableSelect"
        ></LiefengTable>
        <LiefengModal
            :title=" editType == 'add'?'新增':'修改'"
            :value="editStatus"
            @input="changeEditStatus"
            width='480px'
            height='360px'

          >
          <template v-slot:contentarea>
            <EditForm :editType="editType" :formData='formData'></EditForm>
          </template>
          <template v-slot:toolsbar>
              <Button type="primary" style="margin-right:10px" @click="saveEdit">保存</Button>
              <Button type="info" @click="formData = {}; changeEditStatus(false)">取消</Button>
          </template>
        </LiefengModal>
      </template>
    </LiefengContent>
  </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3";
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";
import EditForm from "./childrens/editform";

export default {
  components: {LiefengContent, LiefengTable, LiefengModal,EditForm},
  data() {
    return {
      //分页参数
        talbeColumns: [
            // {
            //     title: "序号",
            //     minWidth: 200,
            //     align: "center",
            //     key:'seq'
                
            // },
            {
                title: "姓名",
                minWidth: 200,
                align: "center",
                key:'userName'
            },
            // {
            //     title: "性别",
            //     minWidth: 200,
            //     align: "center",
            //     key:'name'
            // },
            {
                title: "手机号",
                minWidth: 200,
                align: "center",
                key:'tel'
            },
            // {
            //     title: "角色",
            //     minWidth: 200,
            //     align: "center",
            //     key:'name'
            // },
            // {
            //     title: "排序",
            //     minWidth: 200,
            //     align: "center",
            //     key:'name'
            // },
            // {
            //     title: "状态",
            //     key: "status",
            //     minWidth: 200,
            //     align: "center",
            //     render: (h, params) => {
            //     return h(
            //         'i-Switch',
            //         {
            //         props: {
            //             size: 'large',
            //             value: params.row.sysStatus == 1
            //         },
            //         scopedSlots: {
            //             open: () => h('span', '启用'),
            //             close: () => h('span', '停用')
            //         },
            //         on: {
            //             'on-change': () => {
            //             this.changeStatus(params.row)
            //             }
            //         }
            //         }
            //     )
            //     }
            // },
            {
                title: "操作",
                align: "center",
                fixed: "right",
                width: 180,
                render: (h, params) => {
                return h(
                    "div",
                    {
                        style: {
                        textAlign: "center"
                        }
                    },
                    [
                        h(
                            "Dropdown",
                            {
                            props: {
                                transfer: true
                            }
                            },
                            [
                            h(
                                "Button",
                                {
                                    props: {
                                    type: "success",
                                    size: "small",
                                    icon: "ios-arrow-down"
                                    }
                                },
                                "操作"
                            ),
                            h(
                                "DropdownMenu",
                                {
                                    slot: "list"
                                },
                                [
                                    h(
                                        "DropdownItem",
                                        {
                                        nativeOn: {
                                            click: () => {
                                                this.editType = 'update'
                                                this.editStatus = true
                                                this.formData = {}
                                                this.getdDtails(params.row.staffId)
                                            }
                                        },
                                        style: {
                                            textAlign: "center"
                                        }
                                        },
                                        "修改"
                                    ),
                                    h(
                                        "DropdownItem",
                                        {
                                        nativeOn: {
                                            click: () => {
                                                this.deleteStaff(params.row.staffId)
                                            }
                                        },
                                        style: {
                                            textAlign: "center"
                                        }
                                        },
                                        "删除"
                                    ),
                                ]
                            )
                            ]
                        )
                    ]
                );
                }
            },

        ],
        tableData: [],
        loading: false,
        page: 1,
        pageSize: 20,
        total: 0,

        // 编辑类型
        editType:'',
        
        editStatus:false,
        formData:{}
      
    }
    },
    created() {
        this.getList()
        
    },
    methods: {
        // 获取列表
        getList(){
            this.loading = true
            this.$get('/auth/api/auth/pc/staff/selectByPage',{
                keyword:this.keyword,
                oemCode:parent.vue.oemInfo.oemCode,
                page:this.page,
                pageSize:this.pageSize,
            }).then( res => {
                this.loading = false
                if( res.code == 200 ){
                    this.tableData = res.dataList
                    this.total = res.maxCount
                }else {
                    this.$Message.error({
                        content:"数据获取失败",
                        background:true
                    })
                }
            })
        },
        // 编辑框改变事件
        changeEditStatus(val){
            this.editStatus  = val
        },  
        add(){
            this.formData = {}
            this.editType = 'add'
            this.editStatus = true
        },
        resetBtn(){
            this.keyword = ''
            this.page = 1
            this.pageSize = 20
            this.getList()
        }, 
        getdDtails(staffId){
            this.$get('/auth/api/auth/pc/staff/queryById',{
                staffId,
            }).then( res => {
                if(res.code == 200){
                    // this.$nextTick(() => {
                        let data = {}
                    data.staffId = res.data.staffId
                    data.userName = res.data.userName
                    data.phone = res.data.phone
                    data.oemCode = res.data.oemCode
                    data.roleVos = res.data.roleVos
                    data.password = res.data.password
                    data.roleIds = []
                    let arr = JSON.parse(JSON.stringify(data.roleVos))
                    arr.map( item => {
                        console.log(item)
                        data.roleIds.push(item.roleId)
                    })
                    delete data.roleVos
                    this.formData = data
                    console.log(this.formData)
                    // })
                    
                }else{
                    this.$Message.error({
                        content:res.desc,
                        background:true
                    })
                }
            })
        },
        saveEdit(){
            
            if(!this.formData.userName){
                this.$Message.warning({
                    content:'请输入真实姓名',
                    background:true
                })
                return
            }
            if(!this.formData.phone){
                this.$Message.warning({
                    content:'手机号码',
                    background:true
                })
                return
            }
            let myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            if(!myreg.test(this.formData.phone)){
                this.$Message.warning({
                    content:'请填写正确的手机号码',
                    background:true
                })
                return
            }
            
            if(this.editType == 'add'){
                if(!this.formData.password){
                this.$Message.warning({
                    content:'请输入密码',
                    background:true
                })
                return
            }
            }
            
            let pasRuleA = /^(?=.*[a-zA-Z]+)(?=.*[0-9]+)[a-zA-Z0-9]+$/
            let pasRuleB = /^[0-9]*$/
            let pasRuleC = /^[A-Za-z]+$/
            if( this.formData.password && !( this.formData.password.length >=6 && this.formData.password.length <= 18 && (pasRuleA.test(this.formData.password) || pasRuleB.test(this.formData.password) || pasRuleC.test(this.formData.password)))){
                this.$Message.warning({
                    content:'请输入正确的密码',
                    background:true
                })
                return
            }
            if(this.$route.query.type == '2')
            this.formData.oemCode = parent.vue.oemInfo.oemCode
            if(!this.formData.oemCode){
                this.$Message.warning({
                    content:'请选择运营商',
                    background:true
                })
                return
            }
            let data = JSON.parse(JSON.stringify(this.formData))
            data.tel = data.phone
            if(this.formData.roleIds){
                data.roleIds = this.formData.roleIds.join(',')
            }
            
            
            
            if(this.editType == 'add'){
                this.addStaff(data)
            }else if(this.editType == 'update') {
                this.updateStaff(data)
            }
            
        },
        addStaff(data){
            this.$post('/auth/api/auth/pc/staff/add',{
                ...data
            }).then( res => {
                if( res.code == 200){
                    this.$Message.success({
                        content:'新增成功',
                        background:true
                    })
                    this.editStatus = false
                    this.getList()
                    // this.formData = {}
                }else{
                    this.$Message.error({
                        content:res.desc,
                        background:true
                    })
                }
            })
        },
        // 修改员工
        updateStaff(data){
            this.$post('/auth/api/auth/pc/staff/update',{
                ...data
            }).then( res => {
                if( res.code == 200){
                    this.$Message.success({
                        content:'修改成功',
                        background:true
                    })
                    this.editStatus = false
                    this.getList()
                    // this.formData = {}
                }else{
                    this.$Message.error({
                        content:res.desc,
                        background:true
                    })
                }
            })
        },
        // 删除员工
        deleteStaff(staffId) {
            this.$Modal.confirm({
                title: "删除确定",
                content: "是否删该员工",
                onOk: () => {
                this.$post('/auth/api/auth/pc/staff/delete',{
                    staffId
                }).then(res=>{
                    if(res.code == 200){
                        this.$Message.success({
                            content:'删除成功',
                            background:true
                        })
                    this.getList();
                    }else{
                        this.$Message.error({
                            content:'删除失败',
                            background:true
                        })
                    return
                    }
                })
                },
            });
        },
        
    }
}
</script>
<style lang="less" scoped>
/deep/ #toolsbarRight {
  width: 100%;

  .search {
    width: 100%;
    text-align: right;

    .ivu-form-item {
      margin-bottom: 0;
    }
  }
}

// /deep/.ivu-modal-close {
//   display: none;
// }
.ivu-checkbox-wrapper{
  width: 48%;
  &:first-child{
    width: 100%;
  }
}
</style>