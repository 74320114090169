<template>
    <div>
        <Form
            :label-colon="true"
            :label-width="100"
        >

          <FormItem label="真实姓名" >
            <span slot="label" class="validate">真实姓名:</span>
            <Input style="width:300px" v-model.trim="formData.userName" :maxlength="50" placeholder="请填写个人真实姓名"></Input>
          </FormItem>
          <FormItem label="手机号码" >
            <span slot="label" class="validate">手机号码:</span>
            <Input :disabled="editType == 'add'?false:true " style="width:300px" v-model.trim="formData.phone" :maxlength="11" placeholder="请填写手机号码"></Input>
          </FormItem>
          <FormItem label="密码" >
            <span slot="label" :class="editType=='add'? 'validate':''">密码:</span>
            <Input style="width:300px" v-model.trim="formData.password" :maxlength="18" :placeholder="editType=='add'?'密码至少6个字符，最多18个字符':'修改密码不填默认原密码'"></Input>
          </FormItem>
          <FormItem label="运营商" v-if="$route.query.type == '1'">
            <span slot="label" class="validate">运营商:</span>
            <!-- <Input style="width:300px" v-model.trim="formData.appCode" :maxlength="18" placeholder=""></Input> -->
            <Select v-model="formData.oemCode" style="width:300px" placeholder="请选择运营商">
              <Option v-for="item in oemCodeList" :value="item.value" :key="item.value">{{ item.label }}</Option>
          </Select>
          </FormItem>
          <FormItem label="角色" >
            <span slot="label" >角色:</span>
            <!-- <Input style="width:300px" v-model.trim="formData.appCode" :maxlength="18" placeholder=""></Input> -->
            <Select v-model="formData.roleIds" multiple style="width:300px" placeholder="请选择角色">
              <Option v-for="item in roleList" :value="item.value" :key="item.value">{{ item.label }}</Option>
          </Select>
          </FormItem>
          

        </Form>
    </div>
</template>

<script>
export default {
    props:['formData','editType'],
    data () {
        return {
          oemCodeList:[],
          roleList:[]
        }
    },
    created(){
      this.getOemList()
      this.getRoleList()
    },
    methods:{
      getOemList(){
        this.$get('/auth/api/auth/pc/TenantApi/selectByNoPage',{
        
        }).then( res => {
          if(res.code == 200){
            this.oemCodeList = res.dataList.map( item => {
              return {
                label:item.oemName,
                value:item.tenantId
              }
            })
            console.log(this.oemCodeList)
          }else {
            this.$Message.error({
              content:'运行商获取失败',
              background:true
            })
          }
        })
      },
      getRoleList(){
        this.$get('/auth/api/auth/pc/role/queryByNoPage',{
          oemCode:parent.vue.oemInfo.oemCode,
        }).then( res => {
          if(res.code == 200){
            this.roleList = res.dataList.map( item => {
              return {
                label:item.roleName,
                value:item.roleId,
              }
            })
          }else {
            this.$Message.error({
              content:'角色获取失败',
              background:true
            })
          }
        })
      }
    }
}
</script>
 
<style lang="less" scoped>
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
</style>